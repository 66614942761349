import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useStationaryDetailsPanelStyles = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginTop: 0
    }
  },
  infoSectionsWrapper: {
    [theme.breakpoints.up("md")]: {
      flex: 1,
      borderLeft: `1px solid ${ theme.palette.grey["100"] }`,
      paddingLeft: theme.spacing(9)
    },
  },
  section: {
    display: "flex",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  imageWrapper: {
    flex: 1,
    width: "70px",
    maxWidth: "70px",
    minWidth: "70px",
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  image: {
    maxWidth: "100%",
    width: "100%",
  },
  avatarImageWrapper: {
    [theme.breakpoints.up("md")]: {
      width: "80px",
      maxWidth: "80px",
      minWidth: "80px",
    },
  },
  iconImage :{
    width: theme.spacing(8),
    height: theme.spacing(8),
    top: theme.spacing(-1),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    }
  },
  iconAvatar: {
    "& img": {
      objectFit: "contain",
    },
  },
  hospitalImage: {
    padding: "10px",
    backgroundColor: "white"
  },
  avatarImage: {
    height: "70px",
    background: "transparent",
    border: `1px solid ${ theme.palette.grey["200"] }`,
    [theme.breakpoints.up("md")]: {
      height: "80px",
    },
  },
  sectionTitle: {
    ...theme.typography.h5,
  },
  doctorSectionTitle: {
    [theme.breakpoints.up("md")]: {
      ...theme.typography.h4,
    },
  },
  doctorSectionText: {
    [theme.breakpoints.up("md")]: {
      ...theme.typography.h4,
      fontWeight: 400,
    },
  },
  whenSection: {
    textTransform: "capitalize",
  },
  buttonsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      flexWrap: "wrap",
      flexDirection: "row-reverse",
      marginBottom: 0,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    "&:first-child": {
      marginTop: theme.spacing(5),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2)
      }
    }
  },
  link: {
    ...theme.typography.body2,
    display: "block",
    margin: theme.spacing(1, 0, 3),
    color: theme.palette.primary.dark,
    "&:hover, &:focus": {
      color: theme.palette.primary.darker,
    },
  },
  text: {
    display: "block",
    fontSize: "14px"
  }
}))
