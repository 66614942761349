import { Method } from "axios"
import { AxiosRequestConfig } from "axios"
import i18next from "i18next"

import { ssoMiddlewareHost } from "../app.config"
import { ConsultationListItemModel } from "../components/consultation/Consultation.types"
import { SessionState } from "../store/session/session.types"
import { WordpressPayload } from "../store/wordpress/wordpress.types"

interface RouteConfig extends Partial<AxiosRequestConfig> {
  method: Method,
  url: string,
}

export const logInConfig: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/login-password-grant`
  // token: NOT required
}

export const postForgotPasswordConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/patients/forgot-password"
  // token: NOT required
}

export const postSmsPasswordCodeConfig = (userId: SessionState["id"]): RouteConfig => ({
  method: "POST",
  url: `/api/v2/patients/new-access-code/${userId}`
  // token: NOT required
})

export const putResetPasswordConfig = (session: SessionState): RouteConfig => ({
  method: "PUT",
  url: `/api/v2/patients/new-change-password/${session.id}`,
  headers: {
    "Authorization": `Bearer ${session.token}`
  }
  // token: IS required
})

export const putChangePasswordConfig = (userId: SessionState["id"]): RouteConfig => ({
  method: "PUT",
  url: `/api/v2/patients/change-password/${userId}`
  // token: IS required
})

export const postCheckPriceConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/consultations/check-price"
  // token: NOT required
}

export const getConsultationsConfig: RouteConfig = {
  method: "GET",
  url: "/api/v2/consultations"
  // token: IS required
}
export const confirmConsultationStripePayment = (paymentId: string): RouteConfig => ({
  method: "POST",
  url: `/api/v2/payment/${paymentId}/stripe/confirm`,
  // token: IS not required
})

export const createConsultationStripePayment: RouteConfig = {
  method: "POST",
  url: "/api/v2/payment/stripe/create"
  // token: IS required
}

export const getConsultationConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "GET",
  url: `/api/v2/consultations/${consultationId}?language=${i18next.language}`
  // token: IS required
})

export const getPaymentConfig = (paymentId: string): RouteConfig => ({
  method: "GET",
  url: `/api/v2/payments/${paymentId}`
  // token: IS required
})

export const deleteConsultationConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "DELETE",
  url: `/api/v2/consultations/${consultationId}`
  // token: IS required
})

export const generateConsultationRecommendations = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "POST",
  url: `/api/v2/consultation/${consultationId}/recommendation`
  // token: IS required
})

export const customDeleteConsultationConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "PATCH",
  url: `/api/v2/consultations/${consultationId}/cancel`
  // token: IS required
})

export const getConsultationTermsConfig = (
  startDate: string,
  channel: number,
  specializationID: string,
  language: string,
  poz = 0,
  limit = 6
): RouteConfig => ({
  method: "GET",
  url: `/api/v2/terms/${specializationID}/${startDate}/0?limit=${limit}&channel=${channel}&showFreePozTerms=${poz}&language=${language}`
  // token: IS not required
})

export const patchChangeConsultationTerm = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "PATCH",
  url: `/api/v2/consultations/${consultationId}/terms`
  // token: IS required
})

export const postConsultationFileConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "POST",
  url: `/api/v2/consultations/${consultationId}/upload`,
  headers: {
    "content-type": "multipart/form-data"
  }
  // token: IS required
})

export const postUserFileConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/patient/uploads",
  headers: {
    "content-type": "multipart/form-data"
  }
  // token: IS required
}

export const getExamsConfig: RouteConfig = {
  method: "GET",
  url: "api/v2/patients/current/exams"
  // token: IS required
}

export const getUserDataConfig = (userId: SessionState["id"]): RouteConfig => ({
  method: "GET",
  url: `/api/v2/patients/${userId}`
  // token: IS required
})

export const getClinicsList = (limit = 100): RouteConfig => ({
  method: "GET",
  url: `/api/v2/clinics?limit=${limit}`
  // token: IS not required
})

export const getVouchersDataConfig = (userId: SessionState["id"]): RouteConfig => ({
  method: "GET",
  url: `/api/v2/voucher/patient/${userId}`
  // token: IS required
})

export const postVouchersDataConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/assign-voucher"
  // token: IS required
}

export const refreshTokenConfig: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/refresh-token`
}

export const userEventsConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/users/events"
  // token: IS required
}

export const completeTwoFactorConfig: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/complete-two-factor`
}

export const sendTwoFactorCodeConfig: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/send-two-factor-code`
}

export const getMedicalExamPointsByPostalCodeConfig: RouteConfig = {
  method: "GET",
  url: "/api/v2/medical-examination/get-exam-points"
  // token: IS required
}

export const patchUserPersonalDataConfig = (userId: SessionState["id"]): RouteConfig => ({
  method: "PATCH",
  url: `/api/v2/patients/${userId}`
  // token: IS required
})

export const getTimeZoneOptionsConfig: RouteConfig = {
  method: "GET",
  url: "/api/v2/tools/timezones"
  // token: ??
}

export const getPhoneCountryCodesConfig = (locale: string | null): RouteConfig => ({
  method: "GET",
  url: `/api/v2/tools/country-codes/${locale}`
  // token: ??
})

export const getCountriesConfig = (locale: string | null): RouteConfig => ({
  method: "GET",
  url: `/api/v2/tools/countries/${locale}`
  // token: ??
})

export const getDoctorDataConfig = (doctorId: string): RouteConfig => ({
  method: "GET",
  url: `/api/v2/doctors/${doctorId}`
  // token: ??
})

export const getMedicalDocumentsConfig: RouteConfig = {
  method: "GET",
  url: "/api/v2/medical-documents"
  // token: IS required
}

export const getConsultationChatHistoryConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "GET",
  url: `/api/v2/consultation/${consultationId}/chat/history`,
  // token: IS required
})

export const getPusherKeyConfig: RouteConfig = {
  method: "GET",
  url: "/api/v2/pusher/key",
  // token: IS required
}

export const postPusherAuthConsultationChannelConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/pusher/authConsultationChannel",
  // token: IS required
}

export const postChatMessageConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/consultation/chat/add",
  // token: IS required
}

export const postConsultationChatRatingConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/consultation/rating",
  // token: IS required
}

export const getSpecializationsConfig: RouteConfig = {
  method: "GET",
  url: "/api/v2/specializations"
}

export const postVouchersBuyConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/vouchers/buy",
  // token: IS required
}

export const getMedicalDocumentFileConfig = (documentId: string): RouteConfig => ({
  method: "GET",
  url: `/api/v2/medical-documents/${documentId}`,
  responseType: "blob"
  // token: IS required
})

export const getPrescriptionMedicalDocumentFileConfig = (documentId: string): RouteConfig => ({
  method: "GET",
  url: `/api/v2/medical-documents/prescription/${documentId}`,
  responseType: "blob"
  // token: IS required
})

export const getExamResultFileConfig = (documentId: string): RouteConfig => ({
  method: "GET",
  url: `/api/v2/patient/download/exam-order/${documentId}`,
  responseType: "blob"
  // token: IS required
})

export const getMoneyBoxConfig = (userId: SessionState["id"]): RouteConfig => ({
  method: "GET",
  url: `/api/v2/money-box/${userId}`
  // token: IS required
})

export const getConsultationExamReferralsConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "GET",
  url: `/api/v2/exam/list/${consultationId}`
  // token: IS required
})

export const getExamCartDetails = (examCartId: string): RouteConfig => ({
  method: "GET",
  url: `/api/v2/exam/cart/${examCartId}`
  // token: IS required
})

export const postExamsListConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/ai/exams",
  // token: IS required
}

export const postExamCartConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/exam/cart/create",
  // token: IS required
}

export const postDozCartConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "POST",
  url: `/api/v2/doz/${consultationId}/cart-url`
  // token: IS required
})

export const getDozHashConfig: RouteConfig = ({
  method: "GET",
  url: "/api/v2/doz/hash",
  // token: IS required
})

export const getWordpressPostsConfig = (payload: WordpressPayload): RouteConfig => ({
  method: "GET",
  url: `?rest_route=/wp/v2/${payload.query}`,
})

export const getHealthPreventionPatientMood = (patientId: string, type: number): RouteConfig => ({
  method: "GET",
  url: `/api/v2/health-prevention/patient/${patientId}/mood?type=${type}`,
  // token: IS required
})

export const postHealthPreventionPatientMood = (patientId: string): RouteConfig => ({
  method: "POST",
  url: `/api/v2/health-prevention/patient/${patientId}/mood/save`,
  // token: IS required
})

export const postCalculateServicePrices: RouteConfig = ({
  method: "POST",
  url: "/api/v2/exam/cart/calculate",
  // token: IS required
})

export const postOrderByEan: RouteConfig = ({
  url: "/api/v4/basket/create_order_by_ean",
  method: "POST",
  // hash from /api/v2/doz/hash IS required
})

export const getNearbyPharmacies: RouteConfig = ({
  url: "/api/v4/basket/get_pharmacy_stock_nearby_by_ean",
  method: "POST",
  // hash from /api/v2/doz/hash IS required
})

export const getCoordinatesConfig: RouteConfig = ({
  url: "/search",
  method: "GET",
})

export const generateTwilioVideoTokenConfig = (consultationId: ConsultationListItemModel["id"]): RouteConfig => ({
  method: "POST",
  url: `/api/v2/twilio/generate-token/${consultationId}`
  // token: IS required
})

export const logInMagicLink: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/magic-link/verify`
  // token: NOT required
}

export const logInSimpleMagicLink: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/magic-link/simple-verify`
  // token: NOT required
}

export const addLogMagicLink: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/magic-link/logs`
  // token: NOT required
}

export const getSickLeaveFileConfig = (url: string): RouteConfig => ({
  method: "GET",
  url,
  responseType: "arraybuffer",
  // token: IS required
})

export const logOutConfig = (xTenant: string): RouteConfig => ({
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/logout?x-tenant=${xTenant}`,
  headers: {
    "x-tenant": xTenant
  }
  // token: NOT required
})

export const getManyUserAccountsByLogin = (lang: string): RouteConfig => ({
  method: "GET",
  url: `/${lang}/user/many-by-login`
})

export const postPasswordlessMagiclink = (userId: string): RouteConfig => ({
  method: "POST",
  url: `api/v2/passwordless/${userId}/magiclink`
} as const)

export const postPasswordlessConsultationMagiclink = (userId: string, consultationId: string): RouteConfig => ({
  method: "POST",
  url: `api/v2/passwordless/${userId}/consultations/${consultationId}/magiclink`
} as const)

export const postPasswordlessLogin: RouteConfig = {
  method: "POST",
  url: "/passwordless/login",
  withCredentials: true
}

export const postPasswordlessLoginVerify: RouteConfig = {
  method: "POST",
  url: "/passwordless/login/verify",
  withCredentials: true
}

export const registerUserParentConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/parent"
  // token: IS required
}

export const updateUserParentPersonalDataConfig: RouteConfig = {
  method: "PATCH",
  url: "/api/v2/parent"
  // token: IS required
}

export const sendSickLeaveQuestionnaireAnswers: RouteConfig = {
  method: "POST",
  url: "/api/v2/sick/leave/questionnaire"
  // token: IS required
}

export const checkDeclarationStatus = (id: string): RouteConfig => ({
  method: "POST",
  url: `/api/v2/vpoz/declarations/${id}/confirm`,
  withCredentials: true,
  // token is required ??
})

export const getDeclarationPreview = (declarationId: string): RouteConfig => ({
  method: "GET",
  url: `/api/v2/vpoz/declarations/${declarationId}/preview`,
  withCredentials: true,
  responseType: "blob"
})

export const postStationaryVisitRequestConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/stationary/visit-request",
  // token: IS required
}

export const getDependentAccounts: RouteConfig= {
  method: "GET",
  url: "/api/v2/user/dependent-accounts"
  // token: IS required
}
export const getDependentAccountMagicLink = (policyUID: string): RouteConfig => ({
  method: "POST",
  url: `/api/v2/dependent-account/login/${policyUID}`,
  withCredentials: true,
})

export const getUserLatestBasicMedicalQuestionnaireAnswers = (userId: SessionState["id"]): RouteConfig => ({
  method: "GET",
  url: `/api/v2/patients/latest-survey/${userId}`
  // token: IS required
})

export const DisableMagicLink = (magicLinkId: SessionState["id"]): RouteConfig => ({
  method: "DELETE",
  url: `/api/v2/magic-link/${magicLinkId}`
  // token: IS required
})

const routes = {
  logInConfig,
  getConsultationsConfig,
  getConsultationConfig,
  deleteConsultationConfig,
  postConsultationFileConfig,
  postUserFileConfig,
  getExamsConfig,
  getConsultationChatHistoryConfig,
  getPusherKeyConfig,
  postPusherAuthConsultationChannelConfig,
  postChatMessageConfig,
  postConsultationChatRatingConfig,
  getUserDataConfig,
  getVouchersDataConfig,
  postVouchersDataConfig,
  refreshTokenConfig,
  getMedicalExamPointsByPostalCodeConfig,
  patchUserPersonalDataConfig,
  getTimeZoneOptionsConfig,
  getPhoneCountryCodesConfig,
  getCountriesConfig,
  getMedicalDocumentsConfig,
  getExamResultFileConfig,
  getMedicalDocumentFileConfig,
  getMoneyBoxConfig,
  getConsultationExamReferralsConfig,
  putChangePasswordConfig,
  postExamsListConfig,
  postExamCartConfig,
  completeTwoFactorConfig,
  logInMagicLink,
  addLogMagicLink,
  logOutConfig,
  registerUserParentConfig,
  updateUserParentPersonalDataConfig,
  sendSickLeaveQuestionnaireAnswers,
  checkDeclarationStatus,
  getDeclarationPreview,
  postStationaryVisitRequestConfig,
  getUserLatestBasicMedicalQuestionnaireAnswers
}

export default routes
