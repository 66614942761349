import * as Sentry from "@sentry/react"
import {Severity} from "@sentry/react"
import {push} from "connected-react-router"
import i18next from "i18next"
import jwtDecode from "jwt-decode"
import {SagaIterator} from "redux-saga"
import {call, put, select, takeEvery} from "redux-saga/effects"

import api from "../../api/api"
import {logOutConfig} from "../../api/routes"
import {ssoMiddlewareHost, wasSwitchAccountPageShownOnLogin} from "../../app.config"
import { analytics, LogEventType } from "../../services/analytics"
import store from "../index"
import {selectLogInRedirectUrl} from "../logIn/logIn.selectors"
import {clearRedirectUrlAfterLogin,logInReset} from "../logIn/logIn.slice"
import { clearMedicalExamsPoints } from "../medicalExamsPoints/medicalExamsPoints.slice"
import { clearPatientDocumentation } from "../patientDocumentation/patientDocumentation.slice"
import { getUserDataSaga } from "../user/user.saga"
import { clearUserData, getUserData, setUserDataError } from "../user/user.slice"
import {loginLogoutRedirectUrl} from "./session.utils"
import {RoutePath} from "../../routes/Routes.types"
import {SESSION_STORAGE_KEY} from "./session.types"

import {selectSession} from "./session.selectors"
import { clearSession, initSession, initSessionByFusionAuth,setSession } from "./session.slice"

// initSession when we do not have user data

function* initSessionSaga({payload}: ReturnType<typeof initSession>): SagaIterator {
  const session: ReturnType<typeof selectSession> = yield select(selectSession)
  const redirectUrl: ReturnType<typeof selectLogInRedirectUrl> = yield select(selectLogInRedirectUrl)
  const allowRedirect = !window.location.href.includes("consultation") && !window.location.href.includes("survey") && !window.location.href.includes("user-profile") && !window.location.href.includes("check-declaration")

  yield put(logInReset())

  if (session.id) {
    yield call(getUserDataSaga, getUserData({id: session.id}))
    yield call(() => localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({...session, ...payload})))
    analytics.sendEventWithDefaultParams(LogEventType.LOGIN_SUCCESS)

    if (allowRedirect) {
      yield put(push(redirectUrl ?? `/${i18next.language}`))
      yield put(clearRedirectUrlAfterLogin())
    }
  } else {
    yield call(initSessionByFusionAuthSaga, true, payload?.token, payload?.refresh_token)
  }
}

// initSession in SSO-mode
function* initSessionByFusionAuthSaga(shouldSendErrorToSentry?: boolean, token?: string, refreshToken?: string): SagaIterator {
  try {
    const {data} = yield call(fetchToken, token)

    sessionStorage.removeItem("isLoggedIn")
    if (data && Object.keys(data).length > 0) {
      const sessionData = {
        id: data.token.sub,
        token: data?.token?.accessToken ?? token,
        refresh_token: data?.token?.refreshToken ?? refreshToken,
      }

      yield put(initSession(sessionData))
      analytics.sendEventWithDefaultParams(LogEventType.MIDDLEWARE_USERS_SUCCESS)
    }
  } catch (e) {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn")
    if (isLoggedIn) {
      sessionStorage.removeItem("isLoggedIn")
      e.name = "FailedToLogIn"
      Sentry.captureException(
        e,
        {
          extra: {
            userId: token ? jwtDecode<{ sub: string }>(token).sub : "missing token",
          },
          level: Severity.Debug
        }
      )
    }
    yield put(setUserDataError({
      data: e.response.data,
      status: e.response.status,
    }))

    yield call(() => store.dispatch(push(loginLogoutRedirectUrl())))

    const errorLog = {
      "error_code": e.response.status,
      "error_name": JSON.stringify(e.response.data),
    }
    analytics.sendEventWithDefaultParams(LogEventType.MIDDLEWARE_USERS_ERROR, errorLog)
  }
}

async function fetchToken(token: string) {
  return await api.request({
    method: "GET",
    url: `${ssoMiddlewareHost}/users?disableTokenRefresh=true`,
    withCredentials: true,
    headers: token ? {
      Authorization: token,
    } : undefined,
  })
}

// setSession when we only update session data
function* setSessionSaga({payload}: ReturnType<typeof setSession>) {
  const session: ReturnType<typeof selectSession> = yield select(selectSession)
  yield call(() => localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({...session, ...payload})))
}

function* clearSessionSaga() {
  const clinicId = store.getState().clinic?.clinicSettings?.frontendSettings?.clinicId
  const magicLinkLoginView = window.location.href.includes(RoutePath.LOGIN_MAGIC_LINK.replace(":magicLinkId", ""))

  yield call(() => localStorage.removeItem(SESSION_STORAGE_KEY))
  yield call(() => localStorage.removeItem(wasSwitchAccountPageShownOnLogin))
  yield put(clearUserData())
  yield put(clearMedicalExamsPoints())
  yield put(clearPatientDocumentation())

  if (!magicLinkLoginView) { // don't redirect if session is destroyed on magic link login view (logged patient is not an owner of ML)
    yield call(() => store.dispatch(push(loginLogoutRedirectUrl())))
  }

  yield call(api.request,
    {
      ...logOutConfig(clinicId),
      data: {}
    })

  return
}

export default function* (): SagaIterator {
  const sessionJSON: string = yield call(() => localStorage.getItem(SESSION_STORAGE_KEY))
  const session = JSON.parse(sessionJSON)

  if (!!session?.id) {
    yield put(initSession(session))
  }

  yield takeEvery(initSession, initSessionSaga)
  yield takeEvery(initSessionByFusionAuth, initSessionByFusionAuthSaga)
  yield takeEvery(setSession, setSessionSaga)
  yield takeEvery(clearSession, clearSessionSaga)
}
