import { HealthPreventionLibraryItem } from "../../components/healthPrevention/library/HealthPreventionLibrary.component"
import { WordpressData } from "../../store/wordpress/wordpress.types"

export const getHealthPreventionLibraryItems = (
  data: WordpressData[],
  sliceFirstElement = true,
  useContent = false
): HealthPreventionLibraryItem[] => {
  return data.map((item) => {
    const tags = item._embedded["wp:term"]
      .flatMap(tagsArray => tagsArray.map(tag => ({
        name: tag.name
      })))

    return {
      title: item.title.rendered,
      content: useContent ? item.content.rendered : item.excerpt.rendered,
      url: item.link,
      image: item._embedded["wp:featuredmedia"][0].source_url,
      tags: sliceFirstElement ? tags.slice(1) : tags
    }
  })
}
