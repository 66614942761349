import { makeStyles } from "@material-ui/core"

import { createStyles } from "@material-ui/core/styles"

export const useHealthPreventionLibraryStyles = makeStyles(theme => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(4, 3, 4, 3),
    marginBottom: theme.spacing(2),
    gap: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      padding: theme.spacing(8, 6, 8, 6),
      marginBottom: theme.spacing(4),
    }
  },
  content: {
    flex: "1",
  },
  bodyText: {
    whiteSpace: "break-spaces",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    wordWrap: "break-word",
  },
  title: {
    marginBottom: 0,
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: theme.spacing(2.5) + "px",
    wordWrap: "break-word",
    alignSelf: "center",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
      marginBottom: theme.spacing(3),
      marginLeft: 0,
      lineHeight: theme.spacing(5.5) + "px",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center"
    },
  },
  desktopImageWrapper: {
    display: "flex",
  },
  mobileImageWrapper: {
    display: "flex",
  },
  blueItem: {
    background: "linear-gradient(90deg, #012760 0%, #0D408E 100%)",
    color: theme.palette.common.white
  },
  availableAt: {
    fontSize: theme.spacing(2),
    marginRight: "auto",
    marginLeft: "auto",
    fontWeight: 700,
    lineHeight: theme.spacing(3) + "px",
    color: "rgba(203, 221, 241, 1)",
    [theme.breakpoints.up("md")]: {
      marginRight: "unset",
      marginLeft: "unset",
      fontSize: theme.spacing(3),
    },
  },
  image: {
    borderRadius: theme.spacing(2),
    objectFit: "fill",
    width: "auto",
    maxWidth: theme.spacing(9),
    [theme.breakpoints.up("md")]: {
      borderRadius: theme.spacing(6),
      width: "100%",
      maxWidth: "300px",
    }
  },
  libraryImage: {
    borderRadius: theme.spacing(2),
    objectFit: "fill",
    width: "auto",
    maxWidth: theme.spacing(9),
    [theme.breakpoints.up("md")]: {
      borderRadius: theme.spacing(6),
      width: "100%",
      maxWidth: "300px",
      height: "fit-content"
    }
  },
  backBtnWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto"
    }
  },
  backBtn: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto"
    }
  },
}))
