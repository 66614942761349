import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { push } from "connected-react-router"
import i18next from "i18next"

import {
  isStagingApiEnv,
  ssoMiddlewareHost,
} from "../../app.config"
import store from "../../store"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import {selectToken} from "../../store/session/session.selectors"
import { selectHasUserCompletePersonalData } from "../../store/user/user.selectors"
import FillUserDataInformationPanel
  from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"
import { RoutePath } from "../../routes/Routes.types"

interface MakeStationaryConsultationPageProps {}

const MakeStationaryConsultationPage: FC<MakeStationaryConsultationPageProps> = () => {
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)
  const tokenData = useSelector(selectToken)

  if (hideConsultationBooking) {
    store.dispatch(push(`/${i18next.language}`))

    return null
  }

  if (!checkFeatureAvailability().stationaryVisits) {
    store.dispatch(push(`/${i18next.language}/404`))
  }

  const [isLoadedScript, setIsLoadedScript] = useState<boolean>(false)
  const clinicSettings = useSelector(selectClinicSettings)
  const clinicId = clinicSettings?.frontendSettings?.clinicId
  const telemedicoWidgetWrapperRef = useRef<HTMLDivElement>(null)
  const telemedicoWidgetElement = useMemo(() => document.createElement("div"), [])
  const script = document.createElement("script")
  const widgetConfig = {
    insurerId: clinicId,
    ssoMiddlewareHost,
    telemediApiHost: `https://${clinicSettings?.domain}`,
    lang: i18next.language || "pl",
    token: tokenData
  }

  const clearWidgetData = () => {
    window.Telemedi = null
    setIsLoadedScript(false)
    if (telemedicoWidgetWrapperRef.current) {
      telemedicoWidgetWrapperRef.current.innerHTML = ""
    }
  }

  const handleWidgetLoad = () => {
    setIsLoadedScript(true)
    Telemedi?.(widgetConfig)
  }

  useEffect(() => {
    if (isLoadedScript) {
      clearWidgetData()
    }

    telemedicoWidgetWrapperRef.current?.appendChild(telemedicoWidgetElement)
    script.src = `../scripts/make-stationary-consultation-widget${isStagingApiEnv ? "-stage" : ""}.js`
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      handleWidgetLoad()
    }

    return () => {
      script.removeEventListener("load", handleWidgetLoad)
      document.body.removeChild(script)
      clearWidgetData()
    }
  }, [])

  if (hasUserCompletePersonalData === false) {
    return <FillUserDataInformationPanel redirectRoute={`/${i18next.language}${RoutePath.MAKE_STATIONARY_CONSULTATION}`} />
  }

  return (
    <div ref={telemedicoWidgetWrapperRef} id="telemedi-consbook-stationary-widget"></div>
  )
}

export default MakeStationaryConsultationPage
