import React, {FC, useEffect, useState} from "react"
import {Controller, useForm} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {useHistory, useParams} from "react-router"
import {Box, Button, Divider, Grid, TextField, Typography, useTheme} from "@material-ui/core"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import {AxiosError} from "axios"
import {push} from "connected-react-router"
import { format } from "date-fns"
import i18next from "i18next"

import api from "../../api/api"
import {addLogMagicLink, logInMagicLink, logInSimpleMagicLink} from "../../api/routes"
import {storedMagicLinkKey, wasSwitchAccountPageShownOnLogin} from "../../app.config"
import {SsoErrorCode} from "../../enums/SsoErrorCode"
import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import {useQueryParam} from "../../hooks/useSearchParams"
import store from "../../store"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import {
  logInSubmit,
  setMagicLinkExpired,
} from "../../store/logIn/logIn.slice"
import { selectUserId } from "../../store/session/session.selectors"
import {clearSession} from "../../store/session/session.slice"
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader.component"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import {MAKE_DECLARATION_URL} from "../../pages/declarationSign/DeclarationSignFailed.component"
import PageSection from "../common/pageSection/PageSection.component"
import DatePicker from "../commonFormItems/datePicker/DatePicker.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import PhoneCountryCodeSelect from "../commonFormItems/phoneCountryCodeSelect/PhoneCountryCodeSelect.component"
import {checkFeatureAvailability} from "../../store/clinic/clinicSettings.utils"
import {UserCredentials} from "../../store/logIn/logIn.types"
import { LoginMagicLinkFormValues } from "./LoginMagicLink.types"
import {useLoginMagicLinkStyles} from "./LoginMagicLink.styles"

import LoginMagicLinkTitleNode from "./common/LoginMagicLinkTitleNode"
import {loginMagicLinkValidationSchema} from "./LoginMagicLink.validation"

interface LoginMagicLinkFormProps {}
interface LoginMagicLinkFormParams {
  magicLinkId: string;
}

enum MagicLinkLogTypes {
  TYPE_MAGIC_LINK_SIMPLE_LOGIN = 8,
  TYPE_MAGIC_LINK_EXPIRED = 7,
  TYPE_PASSWORD_ATTEMPT_SUCCESS = 6,
  TYPE_PASSWORD_ATTEMPT_ERROR = 5,
  TYPE_PASSWORD_MAX_ATTEMPT_REACHED = 4,
  TYPE_DOWNLOAD_DOCUMENTATION = 1
}

const EDIT_PATIENT_DATA_PAGE_TYPE = "user-profile"
const EDIT_PATIENT_DATA_URL = "/user-profile#myProfile"
const BIRTH_DATE_INPUT_NAME = "birthdate"
const COUNTRY_INPUT_NAME = "country"

const storeMagicLinkId = (magicLinkId: string) => {
  sessionStorage.setItem(storedMagicLinkKey, magicLinkId)
}

const LoginMagicLinkForm: FC<LoginMagicLinkFormProps> = () => {
  const {magicLinkId} = useParams<LoginMagicLinkFormParams>()
  const { t, i18n } = useTranslation()
  const {goBack} = useHistory()
  const dispatch = useAppDispatch()
  const classes = useLoginMagicLinkStyles()
  const theme = useTheme()
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedBirthDate, setSelectedBirthDate] = useState<string>(format(new Date(), "yyyy-MM-dd"))
  const [country, setCountry] = useState<string>("PL")
  const [globalError, setGlobalError] = useState<AxiosError | null>(null)
  const magicLinkError = globalError?.response?.data?.code === SsoErrorCode.MAGIC_LINK_NOT_MATCHED
  const [customError, setCustomError] = useState("")
  const globalErrorMessage = globalError?.response?.data.message
    ? t(`errors:${globalError?.response?.data.message}`)
    : t(`errors:${globalError?.response?.data}` || "errors:unknownError")
  const loggedPatientId = useAppSelector(selectUserId)
  const phoneNumberQueryParam: string = useQueryParam("phone") ?? ""
  const singleInputFieldOnly: boolean = phoneNumberQueryParam.length > 0
  const {
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)
  const {
    widgetRegisterSettings: { showAreaCodeForLogin }
  } = useAppSelector(selectClinicSettings)

  const getValidationMsg = () => {
    if (customError) {
      return customError
    } else if (magicLinkError) {
      return t("passwordless:invalidMagicLink")
    }

    return globalErrorMessage
  }

  const form = useForm<LoginMagicLinkFormValues>({
    mode: "all",
    resolver: useGetFormValidationRules(loginMagicLinkValidationSchema),
    defaultValues: {
      phone: singleInputFieldOnly ? phoneNumberQueryParam : "",
      country: "PL",
      birthdate: ""
    }
  })

  const addLogMagicLinkRequest = (type: MagicLinkLogTypes) => api.request({
    ...addLogMagicLink,
    data: {
      magicLinkId,
      type
    }
  })

  const handleMagicLinkExpired = async () => {
    await addLogMagicLinkRequest(MagicLinkLogTypes.TYPE_MAGIC_LINK_EXPIRED)
    store.dispatch(setMagicLinkExpired(true))
    store.dispatch(push(`/${i18next.language}`))
  }

  const handleLogin = async (token: UserCredentials, redirectUrl: string|null, expired?: boolean) => {
    //temporary changes - rollback then from master
    await store.dispatch(logInSubmit(token))
    if (!expired) {
      //window.location.href = redirectUrl || "/"
    }
  }

  const goToRedirectUrl = (redirectUrl: string) => {
    window.location.href = redirectUrl // temporary changes - rollback then from master
  }

  const handleDestroySession = async () => {
    if (loggedPatientId) {
      dispatch(clearSession())
    }
  }

  const loggedPatientIsMagicLinksPatient = (patientId: string) => {
    if (patientId === loggedPatientId) {
      return true
    }

    return false
  }

  const handleAutoLoginCheck = async () => {
    try {
      const {data} = await api.request({
        ...logInSimpleMagicLink,
        data: {
          magicLinkId,
          lang: i18next.language
        }
      })

      const destinationUrl = data?.destinationUrl
      let redirectUrl = destinationUrl === EDIT_PATIENT_DATA_PAGE_TYPE ? `/${i18next.language}${EDIT_PATIENT_DATA_URL}` : destinationUrl
      const patientId = data?.patientId

      if (
        destinationUrl === EDIT_PATIENT_DATA_PAGE_TYPE
          && (ewkaVerificationEnabled || onyxVerificationEnabled)
      ) {
        redirectUrl = `/${i18next.language}`
      }

      sessionStorage.removeItem(MAKE_DECLARATION_URL)
      sessionStorage.removeItem(wasSwitchAccountPageShownOnLogin)

      if (data?.token?.accessToken) {
        sessionStorage.setItem(storedMagicLinkKey, magicLinkId)
        await handleDestroySession() // current logged patient id !== ML patient id

        await addLogMagicLinkRequest(MagicLinkLogTypes.TYPE_MAGIC_LINK_SIMPLE_LOGIN)
        await handleLogin(data.token, redirectUrl)
        if (checkFeatureAvailability().showPhoneNumberOnCompleteDataView) {
          sessionStorage.setItem("checkPersonalData", "true")
        }
      } else {
        if (data?.additionalLoginDataRequired) {
          if (!loggedPatientIsMagicLinksPatient(patientId)) {
            await handleDestroySession()
          }
          // show standard ML login modal
          setDataLoading(false)
        } else if (data?.expired) {
          handleMagicLinkExpired()
          return null
        } else {
          if (loggedPatientIsMagicLinksPatient(patientId)) {
            await storeMagicLinkId(magicLinkId)
            goToRedirectUrl(redirectUrl || "/")
          } else {
            await handleDestroySession()
            setDataLoading(false)
          }
        }
      }

    } catch (e) {
      console.error(e)

      if (e.response?.data) {
        setGlobalError(e)
      }

      setDataLoading(false)
    }
  }

  const handleSetSelectedDate = (val: MaterialUiPickersDate|Date) => {
    const birthDate = format(new Date(val || ""), "yyyy-MM-dd")

    setSelectedBirthDate(birthDate)
    form.setValue(BIRTH_DATE_INPUT_NAME, birthDate)
    form.clearErrors(BIRTH_DATE_INPUT_NAME)
  }
  const handleSetCountry = (val: string|null) => {
    setCountry(val ?? "PL")
    form.setValue(COUNTRY_INPUT_NAME, val ?? "PL")
    form.clearErrors(COUNTRY_INPUT_NAME)
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)
    sessionStorage.removeItem(MAKE_DECLARATION_URL)

    try {
      const {data} = await api.request({
        ...logInMagicLink,
        data: {
          phoneNumber: values.phone,
          country: values.country,
          birthDate: selectedBirthDate,
          magicLinkId,
          lang: i18n.language
        }
      })

      if (data?.token?.accessToken) {
        const destinationUrl: string | null = data?.destinationUrl

        await handleLogin(data.token, destinationUrl, data?.expired)
        await addLogMagicLinkRequest(MagicLinkLogTypes.TYPE_PASSWORD_ATTEMPT_SUCCESS)

        if (data?.expired) {
          handleMagicLinkExpired()

          return null
        }

        if (destinationUrl) {
          if (destinationUrl.includes("?showDocs")) {
            await addLogMagicLinkRequest(MagicLinkLogTypes.TYPE_DOWNLOAD_DOCUMENTATION)
          }

          await storeMagicLinkId(magicLinkId)
          //store.dispatch(push(destinationUrl)) // //temporary changes - rollback then from master
          window.location.href = destinationUrl
        }
      }

    } catch (e) {
      console.error(e)

      if (e.response?.data) {
        setGlobalError(e)

        if (e.response?.data?.code === SsoErrorCode.PASSWORDLESS_BIRTH_DATE_NOT_MATCH) {
          setCustomError(t("passwordless:birthDateNotMatch"))
        } else {
          setCustomError(t("passwordless:dataNotMatch"))
        }
      }

      await addLogMagicLinkRequest(MagicLinkLogTypes.TYPE_PASSWORD_ATTEMPT_ERROR)

      if(e.response?.data === "Exceeded authorization limit") {
        await addLogMagicLinkRequest(MagicLinkLogTypes.TYPE_PASSWORD_MAX_ATTEMPT_REACHED)
      }
      // e/o "global" errors
      setLoading(false)
    }
  })

  useEffect(() => {
    handleAutoLoginCheck()
  }, [])

  if (dataLoading) {
    return <LoaderBox boxType="fullPage" boxClassName="b2b-loader" />
  }

  return (
    <PageSection titleNode={
      <LoginMagicLinkTitleNode title="logIn"/>
    }>
      <Box className={classes.sectionBox}>
        <Box mb={1}>
          <Typography variant={"body1"}>
            {singleInputFieldOnly ? t("login:magicLinkBodySingleFieldVariant") : t("login:magicLinkBody")}
          </Typography>
          <Box mt={2} mb={2}>
            <Divider color={theme.palette.grey["100"]}/>
          </Box>
          <Typography variant={"body2"}>
            {t("login:magicLinkBirthdatePasswordBody")}
          </Typography>
        </Box>

        {globalError && (
          <GlobalFormErrorMessage
            message={getValidationMsg()}
          />
        )}

        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Controller
            name={BIRTH_DATE_INPUT_NAME}
            control={form.control}
            render={() => (
              <DatePicker
                disableFuture
                autoOk
                value={selectedBirthDate || ""}
                DialogProps={{
                  className: classes.birthDateCalendar,
                }}
                InputProps={{
                  className: classes.birthDateInput
                }}
                fullWidth={false}
                views={["year", "month", "date"]}
                okLabel={null}
                openTo="year"
                helperText=""
                error={false}
                onChange={(val) => handleSetSelectedDate(val)}
                format="yyyy-MM-dd"
              />
            )}
          />

          {!singleInputFieldOnly && (
            <>
              <Box mt={2} mb={1}>
                <Typography variant={"body2"}>
                  {t("login:magicLinkPhoneNumberPasswordBody")}
                </Typography>
              </Box>

              <Controller
                name="phone"
                control={form.control}
                render={({
                  field: {onChange, value},
                  fieldState: {error, invalid}
                }) => (
                  showAreaCodeForLogin ?
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <PhoneCountryCodeSelect
                          id="phone-country"
                          label={t("user:phoneCountryCode")}
                          placeholder={t("user:phoneCountryCode")}
                          fullWidth
                          disabled={loading}
                          value={country ?? "PL"}
                          onChange={(val) => handleSetCountry(val.target.value)}
                          // InputProps={phoneSelectFieldProps}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          id="phone"
                          label={t("login:magicLinkPhoneNumberPasswordLabel")}
                          placeholder={t("login:magicLinkPhoneNumberPasswordLabel")}
                          fullWidth
                          disabled={loading}
                          value={value}
                          onChange={onChange}
                          error={invalid}
                          helperText={error?.message}
                          required={true}
                          inputProps={{ maxLength: 15 }}
                        />
                      </Grid>
                    </Grid>
                    :
                    <TextField
                      id="phone"
                      label={t("login:magicLinkPhoneNumberPasswordLabel")}
                      placeholder={t("login:magicLinkPhoneNumberPasswordLabel")}
                      fullWidth
                      disabled={loading}
                      value={value}
                      onChange={onChange}
                      error={invalid}
                      helperText={error?.message}
                      required={true}
                      inputProps={{ maxLength: 15 }}
                    />
                )}
              />
            </>
          ) }

          <Box className={classes.buttonsWrapper}>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              type="button"
              className={classes.button}
              onClick={goBack}
            >
              {t("cancel")}
            </Button>

            <Button
              disabled={loading || magicLinkError && !customError}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              startIcon={loading && <ButtonLoader position="prefix"/>}
            >
              {t("login:magicLinkSubmit")}
            </Button>
          </Box>
        </form>
      </Box>
    </PageSection>
  )
}

export default LoginMagicLinkForm
