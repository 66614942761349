import React, { ChangeEvent, FC, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { Box, Typography } from "@material-ui/core"

import CheckboxController from "../../commonFormItems/checkboxController/CheckboxController.component"
import TextFieldController from "../../commonFormItems/textFieldController/TextFieldController.component"
import { CheckboxControllerOption } from "../../commonFormItems/checkboxController/CheckboxController.types"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMedicalSurveyAccordionCheckboxStyles} from "./MedicalSurveyAccordionCheckbox.styles"

interface MedicalSurveyAccordionCheckboxProps {
  name: string;
  checkboxLabel: string;
  inputLabel: string;
  options: CheckboxControllerOption[];
  displayAsInput?: boolean;
}

const MedicalSurveyAccordionCheckbox: FC<MedicalSurveyAccordionCheckboxProps> = ({
  name,
  checkboxLabel,
  inputLabel,
  options,
  displayAsInput
}) => {
  const {
    textarea,
    inputText
  } = useMedicalSurveyAccordionCheckboxStyles()
  const form = useFormContext()
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const currentValue = form.watch(name)

  const handleExpandInput = (name: string, currentValue: number | null) => {
    currentValue == 0 && form.setValue(`${name}Input`, "")
    setExpanded(currentValue ? name : false)
  }

  const handleChange = (onChange: (value: number|string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(String(event.target.value))
  }

  useEffect(()=>{
    handleExpandInput(name, currentValue)
  },[currentValue])

  return  (
    <Accordion
      square
      expanded={ currentValue === "1" }
    >
      <AccordionSummary>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Typography
            variant="body1"
          >
            {checkboxLabel}
          </Typography>
          <CheckboxController
            name={name}
            label=""
            disabled={false}
            options={options}
            handleChange={handleChange}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Typography
            variant="body1"
          >
            {inputLabel}
          </Typography>
          <TextFieldController
            name={`${name}Input`}
            multiline
            placeholder=""
            label=""
            InputProps={{ classes: { input: displayAsInput ? inputText : textarea } }}
          />
        </Box>

      </AccordionDetails>
    </Accordion>
  )
}

export default MedicalSurveyAccordionCheckbox