import React, { FC } from "react"
import { Controller, useFormContext, } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Grid } from "@material-ui/core"

import { useAppSelector } from "../../../../../hooks/storeHooks"
import store from "../../../../../store"
import { selectClinicSettings } from "../../../../../store/clinic/clinicSettings.selectors"
import CountrySelect from "../../../../commonFormItems/countrySelect/CountrySelect.component"
import { PostalCodeInputController } from "../../../../commonFormItems/postalCodeInput/PostalCodeInput.component"
import TextFieldController from "../../../../commonFormItems/textFieldController/TextFieldController.component"
import {
  disabledFormFieldTooltipInputProps, resolveDisableInputProps
} from "../../../../informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import { useEsInputPlaceholderClassName } from "../../../../../utils/useEsInputPlaceholderClass"
import { UserDataType } from "../../../../../store/user/user.types"

interface UserParentAddressDataEditFormProps {
  loading: boolean;
  hideForm?: boolean;
  disableFields?: boolean;
  userParentData?: UserDataType["parent"];
}

export const UserParentAddressDataEditForm: FC<UserParentAddressDataEditFormProps> = ({
  loading,
  hideForm = false,
  disableFields = false,
  userParentData
}) => {
  const {t} = useTranslation()
  const inputPlaceholderClassName = useEsInputPlaceholderClassName()
  const form = useFormContext()
  const countrySelected = form.watch("parentCountry")
  const { frontendSettings: { modules } } = useAppSelector(selectClinicSettings)
  const userData = store.getState().user.userData
  const canPatientEditDataUntilFirstConsultationFinished = modules.includes("enable_data_update") && !userData?.isSensitiveDataEditingDisabled

  const isDisabled = () => {
    if (loading) {
      return true
    }

    if (canPatientEditDataUntilFirstConsultationFinished) {
      return false
    }

    return disableFields
  }

  return (
    <Box mb={4}>
      {!hideForm && (
        <Grid container>
          <Grid item xs={12}>
            <TextFieldController
              name="parentStreet"
              label={t("user:street")}
              disabled={isDisabled()}
              InputProps={
                resolveDisableInputProps(
                  () => disabledFormFieldTooltipInputProps(userParentData?.address?.street, loading),
                  canPatientEditDataUntilFirstConsultationFinished
                )
              }
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name="parentHouseNumber"
                label={t("user:houseNumber")}
                disabled={isDisabled()}
                InputProps={
                  resolveDisableInputProps(
                    () => disabledFormFieldTooltipInputProps(userParentData?.address?.house_number, loading),
                    canPatientEditDataUntilFirstConsultationFinished
                  )
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextFieldController
                className={inputPlaceholderClassName}
                name="parentApartmentNumber"
                label={t("user:apartmentNumber")}
                disabled={isDisabled()}
                InputProps={
                  resolveDisableInputProps(
                    () => disabledFormFieldTooltipInputProps(userParentData?.address?.apartment_number, loading),
                    canPatientEditDataUntilFirstConsultationFinished
                  )
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <PostalCodeInputController
                country={countrySelected}
                name="parentPostalCode"
                label={t("user:postalCode")}
                shrink={true}
                loading={isDisabled()}
                additionalProps={
                  resolveDisableInputProps(
                    () => disabledFormFieldTooltipInputProps(userParentData?.address?.postal_code, loading),
                    canPatientEditDataUntilFirstConsultationFinished
                  )
                }
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextFieldController
                name="parentCity"
                label={t("user:city")}
                disabled={isDisabled()}
                InputProps={
                  resolveDisableInputProps(
                    () => disabledFormFieldTooltipInputProps(userParentData?.address?.city, loading),
                    canPatientEditDataUntilFirstConsultationFinished
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parentCountry"
              control={form.control}
              render={({
                field: {onChange, value},
                fieldState: {error, invalid}
              }) => (
                <CountrySelect
                  id="parentCountry"
                  label={t("user:country")}
                  placeholder={t("user:country")}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={error?.message}
                  disabled={isDisabled()}
                  InputProps={
                    resolveDisableInputProps(
                      () => disabledFormFieldTooltipInputProps(userParentData?.address?.country, loading),
                      canPatientEditDataUntilFirstConsultationFinished
                    )
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}