import dayjs from "dayjs"

import {ManipulateDate} from "../../enums/ManipulateDate"

export const addDaysToSpecifiedDate = (date: string|Date, days: number) => dayjs(date).add(days, ManipulateDate.DAY)

export const addDaysToCurrentDate = (days: number) => dayjs().add(days, ManipulateDate.DAY)

export const subtractDaysFromCurrentDate = (days: number) => dayjs().subtract(days, ManipulateDate.DAY)

export const revertDateFormat = (dateStr: string, isReversed = true) => {
  const [year, month, day] = dateStr.split("-")

  if (!isReversed && (!year || !month || !day || year.includes("_") || day.includes("_") || month.includes("_"))) {
    return dateStr
  }

  if (day.length === 4) {
    if (!isReversed) {
      return `${year}-${month}-${day}`
    }

    return `${day}-${month}-${year}`
  }

  return isReversed 
    ? `${year}-${month}-${day}` 
    : `${day}-${month}-${year}`
}
