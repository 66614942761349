import { PayloadAction } from "@reduxjs/toolkit"
import i18n from "i18next"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"

import api from "../../api/api"
import {DisableMagicLink, getUserDataConfig} from "../../api/routes"
import {storedMagicLinkKey} from "../../app.config"
import { selectMedicalExamsPointsItems } from "../medicalExamsPoints/medicalExamsPoints.selectors"
import { getMedicalExamsPoints } from "../medicalExamsPoints/medicalExamsPoints.slice"
import { UserAddressModel } from "../../components/user/User.types"
import { redirectToError500Page } from "../../utils/handleErrors"
import {
  MedicalExamsPointsState,
  NEAREST_MEDICAL_EXAM_POINTS_LIMIT
} from "../medicalExamsPoints/medicalExamsPoints.types"
import {SessionState} from "../session/session.types"
import {USER_ADDRESS_COORDS_KEY,USER_STORAGE_KEY} from "./user.types"

import { selectUserAddress } from "./user.selectors"
import {
  clearUserData,
  getUserData,
  setForcePasswordChange,
  setUserAddressCoords,
  setUserData,
  setUserDataError,
  setUserRegulationsModalOpen
} from "./user.slice"

export function* getUserDataSaga(action: PayloadAction<Partial<SessionState>>): SagaIterator {
  const oldUserAddress: (UserAddressModel | null) = yield select(selectUserAddress)
  const examPoints: (MedicalExamsPointsState["items"]) = yield select(selectMedicalExamsPointsItems)
  const magicLinkId = sessionStorage.getItem(storedMagicLinkKey)

  if(magicLinkId) {
    yield call(api.request, {...DisableMagicLink(magicLinkId)})
  }

  sessionStorage.removeItem(storedMagicLinkKey)

  try {
    const {data} = yield call(api.request, {...getUserDataConfig(action.payload.id), params: {
      lang: i18n.language
    }})
    yield put(setUserData(data))
    yield call(() => localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(data)))

    const userHasTermsAccepted = data?.terms_accepted && data?.hasLastTermsAccepted
    yield put(setUserRegulationsModalOpen(!userHasTermsAccepted))

    if (data.force_change_password) {
      yield put(setForcePasswordChange(true))
    }

    if (oldUserAddress?.postal_code !== data?.postal_code
      || examPoints.length < NEAREST_MEDICAL_EXAM_POINTS_LIMIT
    ) {
      yield put(getMedicalExamsPoints())
    }

  } catch (e) {
    yield put(setUserDataError({
      data: e.response.data,
      status: e.response.status,
    }))
    yield call(redirectToError500Page, e)
  }
}

function* clearUserSaga() {
  yield call(() => localStorage.removeItem(USER_STORAGE_KEY))
  yield call(() => localStorage.removeItem(USER_ADDRESS_COORDS_KEY))
}

export default function* (): SagaIterator {
  const userJSON: string = yield call(() => localStorage.getItem(USER_STORAGE_KEY))
  const user = JSON.parse(userJSON)
  if (user) {
    yield put(setUserData(user))
  }

  const userAddressCoordsJSON: string = yield call(() => localStorage.getItem(USER_ADDRESS_COORDS_KEY))
  const userAddressCoords = JSON.parse(userAddressCoordsJSON)
  if (userAddressCoords) {
    yield put(setUserAddressCoords(userAddressCoords))
  }

  yield takeLatest(getUserData, getUserDataSaga)
  yield takeLatest(clearUserData, clearUserSaga)
}
