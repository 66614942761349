import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import { useDispatch } from "react-redux"
import { Box, Link, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import { useAppSelector } from "../../../hooks/storeHooks"
import store from "../../../store"
import {
  selectMedicalExamsPointsItems,
  selectMedicalExamsPointsLoading,
} from "../../../store/medicalExamsPoints/medicalExamsPoints.selectors"
import { selectLoading } from "../../../store/user/user.selectors"
import { setUserAddressEditModalOpen } from "../../../store/user/user.slice"
import ButtonTextUnderlined from "../../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import LoaderBox from "../../common/loaderBox/LoaderBox.component"
import { useMedialExamsPointsStyles } from "./MedicalExamsPoints.styles"

interface MedicalExamsPointsProps {}

const MedicalExamsPoints: FC<MedicalExamsPointsProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const medialExamPoints = useAppSelector(selectMedicalExamsPointsItems)
  const examsPointsLoading = useAppSelector(selectMedicalExamsPointsLoading)
  const userDataLoading = useAppSelector(selectLoading)
  const classes = useMedialExamsPointsStyles()
  const loaderVisible = examsPointsLoading || userDataLoading
  const examsPointsAvailable = medialExamPoints.length > 0
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const patientEditAllowed = store.getState()?.clinic?.clinicSettings?.patientEditAllowed ?? true

  return (
    <>
      <Box pb={examsPointsAvailable ? 4 : 1}>
        <Typography
          variant={isSmUp ? "h4" : "h5"}
        >
          { `${t("referrals:facilitiesAroundYou")}:` } 
        </Typography>
      </Box>

      {
        loaderVisible
          ? <LoaderBox />
          : (
            examsPointsAvailable
              ? medialExamPoints.map((item) => {
                const examPointAddress = `${item.city} ${item.street} ${item.postalCode}`
    
                return (
                  <Box 
                    key={item.id}
                    className={classes.container}
                  >
                    <Box>
                      <Typography 
                        variant={isSmUp ? "h4" : "h5"}
                        className={classes.address}>
                        { `${item.pointType.toUpperCase()} ${item.street}, ${item.city}` }
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body2"
                        className={classes.label}
                      > 
                        { t("referrals:distanceFromYou", { km: item.distance }) }
                      </Typography>
    
                      <Typography
                        variant="body2"
                        className={classes.label}
                      >
                        <Link
                          href={`https://maps.google.com/?q=${examPointAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="always"
                          className={classes.label}
                        >
                          <ButtonTextUnderlined
                            color="primary"
                          >
                            { t("referrals:seeExamPointOnMap") }
                          </ButtonTextUnderlined>
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                )
              })
              : <Box>{ t("referrals:noResultsMapErrorCaption") }</Box>
          )
      }

      {
        !loaderVisible && patientEditAllowed && (
          <ButtonTextUnderlined 
            color="primary"
            className={classes.buttonEditAddress}
            onClick={() => dispatch(setUserAddressEditModalOpen(true))}
          >
            { t("user:editAddress") }
          </ButtonTextUnderlined>
        )
      }
    </>
  )
}

export default MedicalExamsPoints
