import i18next from "i18next"
import * as yup from "yup"

import { isAdultFromPesel, isValidBirthDate, isValidPesel, nameRegexp } from "../../user/userProfile/userPersonalData/UserPersonalDataEditModal/UserPersonalDataEditModal.validation"
import { getDifferentFormatDateValidationMessage } from "../../../utils/getDifferentFormatDateValidationMessage"
import { isAdult } from "../../../utils/isAdult"
import {Gender, MainCountriesTypes, UserDataType, UserDocumentData, UserParentDataType} from "../../../store/user/user.types"
import {CountryAbbreviation, CountryOption} from "../../commonFormItems/countrySelect/Country.types"
import {TimeZoneOption} from "../../commonFormItems/timeZoneSelect/TimeZoneSelect.types"

type UserDataValidation = {
  // Patient values
  firstName: string;
  lastName: string;
  nationality: CountryOption["value"];
  pesel?: string;
  documentType?: UserDocumentData["value"];
  identityNumber?: string;
  birthDate?: string;
  gender: Gender | null;
  defaultTimezone?: TimeZoneOption | null;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  country: string;
  // Parent values
  parentFirstName: string;
  parentLastName: string;
  parentNationality: CountryOption["value"];
  parentPesel?: string;
  parentDocumentType?: UserDocumentData["value"];
  parentIdentityNumber?: string;
  parentBirthDate?: string;
  parentGender: Gender | null;
  parentDefaultTimezone?: TimeZoneOption | null;
  parentStreet: string;
  parentHouseNumber: string;
  parentPostalCode: string;
  parentCity: string;
  parentCountry: string;
  phoneNumber: string;
  phoneCountryCode: string;
  showPhoneNumber?: string;
}

type schemaType = {
  fields: {
    [fieldKey: string]: string
  }
}

export const postalCodeSchema = (countryName: string) => {
  const requiredMessage = i18next.t("errors:required")
  const incorrectPostalCodeMessage = i18next.t("errors:incorrectPostalCode")
  const onlyNumbers = /[^0-9 ]/g
  const maxCodeNumbersPl = 5

  return yup.string()
    .required(requiredMessage)
    .when(countryName, {
      is: (country: CountryAbbreviation) => country === MainCountriesTypes.PL,
      then: yup.string()
        .test("length", incorrectPostalCodeMessage, (postalCode?: string) => postalCode?.replace(onlyNumbers, "").length === maxCodeNumbersPl),
    })
}

export const userDataValidationSchema = (
  userPesel: UserDataType["pesel"],
  userParentPesel: UserParentDataType["pesel"],
  isDifferentDateFormat?: boolean
): yup.SchemaOf<UserDataValidation> => {
  const requiredMessage = i18next.t("errors:required")
  const wrongPeselMessage = i18next.t("errors:form.wrong_pesel")
  const wrongParentAgeFromPeselMessage = i18next.t("errors:wrongParentAgeFromPeselMessage")
  const wrongParentAgeFromBirthDateMessage = i18next.t("errors:wrongParentAgeFromBirthDateMessage")
  const unknownErrorMessage = i18next.t("errors:unknownError")
  const incorrectFirstNameMessage = i18next.t("errors:incorrectFirstNameMessage")
  const incorrectLastNameMessage = i18next.t("errors:incorrectLastNameMessage")

  return yup.object().shape({
    // -------------- Patient Fields --------------
    firstName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectFirstNameMessage),
    lastName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectLastNameMessage),
    nationality: yup.string()
      .required(requiredMessage),
    pesel: yup.string()
      .required(requiredMessage)
      .when("pesel", {
        is: () => !userPesel,
        then: yup.string()
          .when("underageUserCheckbox", {
            is: (underageUserCheckbox: boolean) => underageUserCheckbox,
            then: yup.string()
              .test("is-adult", unknownErrorMessage, (value?: string) =>
                !isAdultFromPesel(value)
              )
              .test("is-valid-pesel", wrongPeselMessage, (value?: string) =>
                isValidPesel(value)
              ),
            otherwise: yup.string()
              .test("is-valid-pesel", wrongPeselMessage, (value?: string) =>
                isValidPesel(value)
              )
          })
      }),
    documentType: yup.string()
      .required(requiredMessage),
    identityNumber: yup.string()
      .required(requiredMessage),
    birthDate: yup.string()
      .required(requiredMessage)
      .test("is-valid-date", getDifferentFormatDateValidationMessage(isDifferentDateFormat), (value?: string) =>
        isValidBirthDate(value),
      )
      .when("underageUserCheckbox", {
        is: (underageUserCheckbox: boolean) => underageUserCheckbox,
        then: yup.string()
          .test("is-adult", unknownErrorMessage, (value?: string) =>
            !isAdult(value),
          ),
      }),
    gender: yup.mixed()
      .oneOf(Object.values(Gender), requiredMessage)
      .required(requiredMessage),
    defaultTimezone: yup.string()
      .required(requiredMessage),
    street: yup.string()
      .required(requiredMessage),
    houseNumber: yup.string()
      .required(requiredMessage),
    postalCode: postalCodeSchema("country"),
    city: yup.string()
      .required(requiredMessage),
    country: yup.string()
      .required(requiredMessage),
    phoneNumber: yup.string()
      .required(i18next.t("errors:required")),
    phoneCountryCode: yup.string()
      .required(i18next.t("errors:required")),
    // -------------- Parent Fields --------------
    parentFirstName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectFirstNameMessage),
    parentLastName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectLastNameMessage),
    parentNationality: yup.string()
      .required(requiredMessage),
    parentPesel: yup.string()
      .required(requiredMessage)
      .when("parentPesel", {
        is: () => !userParentPesel,
        then: yup.string()
          .test("is-valid-pesel", wrongPeselMessage, (value?: string) =>
            isValidPesel(value)
          )
          .test("is-adult", wrongParentAgeFromPeselMessage, (value?: string) =>
            isAdultFromPesel(value) ?? false,
          )
      }),
    parentDocumentType: yup.string()
      .required(requiredMessage),
    parentIdentityNumber: yup.string()
      .required(requiredMessage),
    parentBirthDate: yup.string()
      .required(requiredMessage)
      .test("is-valid-date", getDifferentFormatDateValidationMessage(isDifferentDateFormat), (value?: string) => 
        isValidBirthDate(value),
      )
      .test("is-adult", wrongParentAgeFromBirthDateMessage, (value?: string) =>
        isAdult(value) ?? false,
      ),
    parentGender: yup.mixed()
      .oneOf(Object.values(Gender), requiredMessage)
      .required(requiredMessage),
    parentDefaultTimezone: yup.string()
      .required(requiredMessage),
    parentStreet: yup.string()
      .required(requiredMessage),
    parentHouseNumber: yup.string()
      .required(requiredMessage),
    parentPostalCode: postalCodeSchema("parentCountry"),
    parentCity: yup.string()
      .required(requiredMessage),
    parentCountry: yup.string()
      .required(requiredMessage),
  }, [
    ["pesel", "pesel"],
    ["parentPesel", "parentPesel"]
  ]).required().when((values: string, schema: schemaType) => {
    for (const key in schema.fields) {
      const currentField = document.getElementById(key)

      if (currentField === null) {
        delete schema.fields[key]
      }
    }
  })
}