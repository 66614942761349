import React, { FC, useEffect, useState } from "react"

import { useGetWordpressQuery } from "../../../../store/wordpress/wordpress.api"
import { HealthPreventionLibraryItem } from "../../../../components/healthPrevention/library/HealthPreventionLibrary.component"
import HealthPreventionLibraryItems
  from "../../../../components/healthPrevention/library/items/HealthPreventionLibraryItems.component"
import Loader from "../../../../components/loader/Loader.component"
import { getHealthPreventionLibraryItems } from "../../../../utils/mentalHealth/getHealthPreventionLibraryItems"
import { RoutePath } from "../../../../routes/Routes.types"

const MentalHealthLibraryPage: FC = () => {
  const [libraryItems, setLibraryItems] = useState<HealthPreventionLibraryItem[]>([])
  const { data, isLoading } = useGetWordpressQuery({
    query: "posts&_embed=1&_fields=id,link,excerpt,content,_embedded,_links,title"
  })

  useEffect(() => {
    if (!data || isLoading) {
      return
    }
    
    setLibraryItems(getHealthPreventionLibraryItems(data))
  }, [data, isLoading])
  
  if (isLoading) {
    return <Loader/>
  }

  return <HealthPreventionLibraryItems 
    redirectRoute={RoutePath.MENTAL_HEALTH}          
    items={libraryItems}
    btnLabel={"mentalHealth:goToArticle"}
  />
}

export default MentalHealthLibraryPage
