import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { Box, Button } from "@material-ui/core"

import Loader from "../../loader/Loader.component"
import { useHealthPreventionIframeViewStyles } from "./HealthPreventionIframeView.styles"

interface RouteState {
  iframeSrc?: string;
  redirectRoute?: string;
}

const HealthPreventionIframeViewPage: FC = () => {
  const { t, i18n } = useTranslation()
  const { push } = useHistory()
  const location = useLocation()
  const classes = useHealthPreventionIframeViewStyles()
  const [loading, setLoading] = useState(true)
  const { iframeSrc, redirectRoute } = location.state as RouteState || {}

  if (!iframeSrc || !redirectRoute) {
    push(`/${i18n.language}`)

    return null
  }

  const handleLoad = () => {
    setLoading(false)
  }

  return <>
    <Box className={classes.boxContainer}>
      <Button
        className={classes.backBtn}
        variant="outlined"
        color="primary"
        onClick={() => push(redirectRoute)}
      >
        {t("goToHomepage")}
      </Button>
      <Box className={classes.iframeContainer}>
        {loading && <Loader mb={3}/>}
        <iframe className={classes.iframe} src={iframeSrc} onLoad={handleLoad}/>
      </Box>
    </Box>
  </>
}

export default HealthPreventionIframeViewPage
